<template>
  <div class="discountCoupon">
    <mt-header :title="$t('discountCoupon')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)" icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <mt-navbar v-model="selected">
      <mt-tab-item id="0">{{ $t('toBeExchanged') }}</mt-tab-item>
      <mt-tab-item id="1">{{ $t('exchanged') }}</mt-tab-item>
      <mt-tab-item id="2">{{ $t('expired') }}</mt-tab-item>
    </mt-navbar>
    <mt-tab-container v-model="selected">
      <mt-tab-container-item id="0">
        <div>
          <mt-cell v-for="(item, index) in coupon['0']" :key="index">
            <label class="mint-checklist-label" slot="title">
              <span class="mint-checkbox">
                <input class="mint-checkbox-input" type="checkbox" v-model="currentValue" :value="item.id">
                <span class="mint-checkbox-core"></span>
              </span>
              <div class="content">
                <div class="amount">{{ item.amount | formatMoney }}</div>
                <div>
                  <div class="expireDate">{{$t('validUntil')}} {{ item.expireDate }}</div>
                  <div class="remark">{{ item.remark }}</div>
                </div>
              </div>
            </label>
          </mt-cell>
        </div>
        <div class="bottom" v-if="coupon['0'].length">
          <mt-button type="primary" size="large" plain :disabled="!currentValue.length" @click="submit('N')">{{
            $t("exchangeImmediately")
          }}</mt-button>
        </div>
      </mt-tab-container-item>
      <mt-tab-container-item id="1">
        <mt-cell v-for="(item, index) in coupon['1']" :key="index">
          <label class="mint-checklist-label" slot="title">
            <div class="content">
              <div class="amount">{{ item.amount | formatMoney }}</div>
              <div>
                <div class="expireDate">{{$t('validUntil')}} {{ item.expireDate }}</div>
                <div class="remark">{{ item.remark }}</div>
              </div>
            </div>
          </label>
        </mt-cell>
      </mt-tab-container-item>
      <mt-tab-container-item id="2">
        <mt-cell v-for="(item, index) in coupon['2']" :key="index">
          <label class="mint-checklist-label" slot="title">
            <div class="content">
              <div class="amount">{{ item.amount | formatMoney }}</div>
              <div>
                <div class="expireDate">{{$t('validUntil')}} {{ item.expireDate }}</div>
                <div class="remark">{{ item.remark }}</div>
              </div>
            </div>
          </label>
        </mt-cell>
      </mt-tab-container-item>
    </mt-tab-container>
  </div>
</template>

<script>
import callPhone from '@/components/callPhone.vue'
import { myCoupon, useMyCoupon } from '../../utils/api'
export default {
  name: 'DiscountCoupon',
  components: {
    callPhone
  },
  data() {
    return {
      selected: '0',
      currentValue: [],
      coupon: {
        '0': [],
        '1': [],
        '2': []
      }
    }
  },
  watch: {
    selected(val) {
      this.getMyCoupon(val)
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      await this.getMyCoupon(this.selected)
      this.$indicator.close();
    },
    // 获取优惠卷
    async getMyCoupon(status) {
      await this.$axios({
        method: "get",
        url: myCoupon,
        params: {
          status
        }
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.coupon[status] = e.body
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
    },
    // 立即兑换
    async submit(confirm) {
      await this.$axios({
        method: "post",
        url: useMyCoupon + '?couponId=' + this.currentValue,
        params: {
          confirm,
        }
      })
        .then(async (e) => {
          if (e.status.code == "119") {
            this.$messagebox.confirm(e.status.msg || this.$t('couponPopupTips'), this.$t('couponPopupTitle'), {
              confirmButtonText: this.$t('confirm'),
              cancelButtonText: this.$t('cancel'),
            }).then(() => {
              this.submit('Y')
            }).catch(() => {});
          } else {
            this.getMyCoupon(this.selected)
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
    }
  }
}
</script>

<style lang="scss" scoped>
.discountCoupon {
  .mint-tab-container {
    margin: 10px 0;
  }
  .bottom {
    padding: 60px 20px 20px 20px;
  }
  /deep/ .mint-checklist-label {
    display: flex;
    .mint-checkbox {
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  /deep/ .content {
    flex: 1;
    padding: 10px 0px;
    .amount {
      font-size: 20px;
    }
    .expireDate {
      padding: 6px 0px 4px 0px;
    }
    .remark {
      font-size: 12px;
    }
  }
}
</style>